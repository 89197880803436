@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    background-color: rgb(242, 242, 242);
}
kbd {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.1em 0.5em;
    margin: 0 0.2em;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    background-color: #f7f7f7;
}
