.hinge-shadow {
  box-shadow: 0 7px 30px -10px rgba(150,170,180,0.2);
}
.hinge-prompt {
  font-family: 'Poppins';
}
.hinge-answer {
  font-family: 'Source Serif Pro';
}
.btn {
  max-width: 80px;
}
